"use client";

import cn from "@/utils/cn";
import Image from "next/image";
import { useState, useRef, useEffect } from "react";

import drawerIcon from "@/assets/icons/drawer-icon.svg";

type MobileLinksNavContainerProps = React.HTMLAttributes<HTMLDivElement>;

export default function MobileLinksNavContainer({
  className,
  children,
  ...props
}: MobileLinksNavContainerProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const drawerRef = useRef(null); // Create a ref for the drawer
  const buttonRef = useRef(null); // Reference to the button

  useEffect(() => {
    // Function to handle the event of clicking outside the drawer
    function handleClickOutside(event: MouseEvent) {
      if (
        drawerRef.current &&
        // @ts-expect-error Fixing these legacy ref errors are not worth the time (for now).
        !drawerRef.current.contains(event.target) &&
        // @ts-expect-error Same as above.
        !buttonRef.current.contains(event.target)
      ) {
        // TODO: and also the case where we haven't literally clicked the button to open the drawer.
        setIsDrawerOpen(false); // Close the drawer
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerRef]);

  return (
    <div className={cn("relative", className)} {...props}>
      <button ref={buttonRef} onClick={() => setIsDrawerOpen((prev) => !prev)}>
        <Image src={drawerIcon} alt="Nav drawer open button" />
      </button>

      {isDrawerOpen && (
        <div ref={drawerRef} className={cn()}>
          {children}
        </div>
      )}
    </div>
  );
}
