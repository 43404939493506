"use client";
import cn from "@/utils/cn";
import { Content } from "@prismicio/client";
import { PrismicNextImage } from "@prismicio/next";
import LinksNav from "./links-nav";
import MobileLinksNavContainer from "./mobile-links-nav-container";
import Link from "next/link";
import Button from "@/components/buttons";
import { useZStore } from "@/utils/z-store";

type HeaderProps = {
  logo: Content.LandingPageDocumentData["header_logo"];
  links: Content.LandingPageDocumentData["header_links"];
} & React.HTMLAttributes<HTMLDivElement>;

export default function Header({
  logo,
  links,
  className,
  ...props
}: HeaderProps) {
  const { data, actions } = useZStore();

  return (
    <div
      className={cn(
        "flex w-full sticky top-0 bg-kd-white z-30 mx-auto max-w-[1920px] shadow-lg shadow-kd-black/5 py-4 xl:pl-32 xl:pr-40 md:px-20 px-6 items-center justify-center h-20 md:h-[85px] xl:h-[104px]",
        className,
      )}
      {...props}
    >
      <div className="flex w-full max-w-[1920px] items-center justify-between">
        <Link href={"/"}>
          <PrismicNextImage
            field={logo}
            alt=""
            className="w-[180px] md:w-[220px] xl:w-[300px]"
          />
        </Link>

        <div className="flex items-center justify-center space-x-10 xl:ml-20 xl:space-x-[70px] ">
          {/* Links Nav */}
          <LinksNav
            links={links}
            className={cn(
              "hidden lg:flex md:items-center xl:space-x-[58px] md:space-x-[66px]",
            )}
          />

          <div className="hidden gap-2 md:flex md:gap-7 lg:flex-row ">
            {/* <Link href="?modal=true&modalName=sign-in"> */}
            <Button
              variant="secondary"
              className="whitespace-nowrap rounded-xl px-[13.5px] py-[7.5px] font-semibold xl:px-[16px] xl:py-[8px]"
              onClick={() =>
                // actions.openModal({ modalName: "start-free-trial" })
                actions.openModal({ modalName: "sign-in" })
              }
            >
              Sign In
            </Button>
            {/* </Link> */}
            {/* <Link href="?modal=true&modalName=start-free-trial"> */}
            <Button
              variant="primary"
              className="whitespace-nowrap rounded-xl px-[13.5px] py-[7.5px] font-semibold xl:px-[16px] xl:py-[8px]"
              onClick={() =>
                // actions.openModal({ modalName: "start-free-trial" })
                actions.openModal({modalName:"sign-up"})
              }
            >
              Start Free Trial
            </Button>
            {/* </Link> */}
          </div>

          {/* Mobile Links Drawer */}
          <MobileLinksNavContainer className={cn("block lg:hidden")}>
            {!data.modal.isOpen && (
              <LinksNav
                links={links}
                className={cn(
                  "absolute flex flex-col bg-white border  right-0 divide-y top-14 rounded-2xl w-[280px] h-[280px]a h-fit",
                )}
                innerClassName={cn(
                  "w-full text-left h-[60px]s pl-[26px] py-5 ",
                )}
                enableMobileDivider
              />
            )}
          </MobileLinksNavContainer>
        </div>
      </div>
    </div>
  );
}
