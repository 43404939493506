"use client";
import Button from "@/components/buttons";
import cn from "@/utils/cn";

import { RichTextField } from "@prismicio/client";
import { PrismicRichText } from "@prismicio/react";
import Link from "next/link";
import BackgroundImage from "@/assets/cta-banner-background.svg";
import MobileBackgroundImage from "@/assets/cta-banner-mobile-background.svg";

import Image from "next/image";
import { useZStore } from "@/utils/z-store";

type CTABannerProps = {
  text: RichTextField;
} & React.HTMLAttributes<HTMLDivElement>;

export default function CTABanner({
  text,
  className,
  ...props
}: CTABannerProps) {
  const { actions } = useZStore();

  return (
    <div
      className={cn(
        "bg-kd-lte-blue  bg-gradient-to-b md:py-[45px] px-8 md:px-[30.5px] xl:px-[96px] space-y-10 md:space-y-0 flex md:flex-row flex-col md:justify-between md:items-center py-8 xl:pt-[68px] xl:pb-[56px] w-full rounded-[15px] relative overflow-hidden",
        className,
      )}
      {...props}
    >
      <>
        <Image
          src={BackgroundImage}
          alt=""
          className="absolute inset-y-0 right-0 -z-0 hidden h-full md:block"
        />
        <Image
          src={MobileBackgroundImage}
          alt=""
          className="absolute -top-5 bottom-0 right-0 -z-0 h-full  object-cover  md:hidden "
        />
      </>
      <PrismicRichText
        field={text}
        components={{
          paragraph: ({ children }) => {
            return (
              <p className="z-10 w-[90%] text-[27px] font-semibold leading-9 text-kd-white md:w-3/4 xl:text-[38px] xl:leading-[54px]">
                {children}
              </p>
            );
          },
        }}
      />
      <div className="z-10 flex md:w-1/4 md:justify-end">
        {/* <Link href="/"> */}
        <Button
          variant="primary"
          className="px-[12.5px]xl:px-[15px]  mb-[13px] mt-2 h-[30px] w-[130px] whitespace-nowrap rounded-[9.6px] border border-[#61A1FF] bg-kd-lte-blue py-2 text-[12.8px] font-semibold leading-6 text-white md:mt-[6px] xl:mt-[14px] xl:h-10 xl:w-[163px] xl:rounded-[12px] xl:py-2 xl:text-base "
          onClick={() => actions.openModal({ modalName: "start-free-trial" })}
        >
          Join the Waitlist
        </Button>
        {/* </Link> */}
      </div>
    </div>
  );
}
