"use client";
import cn from "@/utils/cn";
import { RichTextField } from "@prismicio/client";
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import { Fragment } from "react";
import { usePathname } from "next/navigation";
import { useZStore } from "@/utils/z-store";

type LinksNavProps = {
  links: any;
  innerClassName?: string;
  enableMobileDivider?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export default function LinksNav({
  links,
  className,
  innerClassName,
  enableMobileDivider = false,
  ...props
}: LinksNavProps) {
  const pathname = usePathname();
  const { actions } = useZStore();
  return (
    <div className={cn("overflow-hidden", className)} {...props}>
      {links.map(({ link, text }: any, index: any) => (
        <Fragment key={index}>
          <PrismicLink
            field={link}
            className={cn(
              "group text-kd-black hover:text-kd-lte-blue  hover:bg-[#EDF2F6] hover:lg:bg-white",
              innerClassName,
            )}
          >
            <PrismicRichText
              field={text as RichTextField}
              components={{
                paragraph: ({ children }) => (
                  <p
                    className={cn(
                      "leading-6 text-[#2B3640] group-hover:text-kd-lte-blue text-[12px] xl:text-base font-medium whitespace-nowrap",
                      link.url === pathname
                        ? "text-kd-lte-blue font-semibold"
                        : "font-medium",
                    )}
                  >
                    {children}
                  </p>
                ),
              }}
            />
          </PrismicLink>

          {/* {enableMobileDivider && (
            <div className="h-[1px] w-full bg-kd-grey/10" />
          )} */}
        </Fragment>
      ))}
      <div
        onClick={() => actions.openModal({ modalName: "sign-in" })}
        className={cn(
          "flex-col lg:hidden group cursor-pointer hover:bg-[#EDF2F6] hover:lg:bg-white",
          innerClassName,
        )}
      >
        <p
          className={cn(
            " leading-6 text-[#2B3640] group-hover:text-kd-lte-blue text-[12px] xl:text-base font-medium whitespace-nowrap",
          )}
        >
          Sign In
        </p>
        <p className="w-[45%] text-[10px] text-[#96A5B8]">
          Privacy and Terms Cookie Preferences
        </p>
      </div>
    </div>
  );
}
